import axios from "axios";
import router from "@/router";
import { API_BASE_URL } from "@/config/setting";

import { ElMessageBox } from "element-plus/es";

const request = axios.create({
  baseURL: API_BASE_URL,
});

// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
request.interceptors.request.use(
  (config) => {
    config.headers["Content-Type"] = "application/json;charset=utf-8";
    let user = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null;
    if (user) {
      config.headers["token"] = user.token; // 设置请求头
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * 添加响应拦截器
 */
request.interceptors.response.use(
  (res) => {
    // 登录过期处理
    if (res.data?.code === 401) {
      const currentPath = router.currentRoute.path;
      if (currentPath === "/") {
        router.push("/login");
      } else {
        ElMessageBox.close();
        ElMessageBox.alert("登录状态已过期, 请退出重新登录!", "系统提示", {
          confirmButtonText: "重新登录",
          callback: (action) => {
            if (action === "confirm") {
              router.push("/login");
            }
          },
          draggable: true,
        });
      }
      return Promise.reject(new Error(res.data.message));
    }

    return res;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default request;
