import { createStore } from "vuex";
import router, { resetRouter } from "@/router";

export default createStore({
  state: {
    webName: "",
  },
  mutations: {
    setWebName(state) {
      state.webName = localStorage.getItem("webName");
    },

    logout() {
      // 清空缓存
      localStorage.removeItem("user");
      localStorage.removeItem("menus");
      router.push("/login");

      // 重置路由
      resetRouter();
    },
  },
  actions: {},
  modules: {},
});
