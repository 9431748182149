import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Index",
    meta: {
      title: "欢迎页面",
      icon: "lock",
    },
    component: () => import("../views/Index"),
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      title: "登录页面",
      icon: "lock",
    },
    component: () => import("../views/login/Login"),
  },
  {
    path: "/register",
    name: "Register",
    meta: {
      title: "注册页面",
      icon: "lock",
    },
    component: () => import("../views/login/Register"),
  },
  {
    path: "/find",
    name: "Find",
    meta: {
      title: "找回密码",
      icon: "lock",
    },
    component: () => import("../views/login/Find"),
  },
  {
    path: "/rest",
    name: "Rest",
    meta: {
      title: "重置密码",
      icon: "lock",
    },
    component: () => import("../views/login/Rest"),
  },
  {
    path: "/qq",
    name: "QQ",
    meta: {
      title: "QQ登录",
      icon: "lock",
    },
    component: () => import("../views/login/QQ"),
  },
  {
    path: "/404",
    meta: {
      title: "404页面",
      icon: "lock",
    },
    name: "404",
    component: () => import("../views/404"),
  },
  {
    path: "/:pathMatch(.*)",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// 注意：刷新页面会导致页面路由重置
export const setRoutes = () => {
  const storeMenus = localStorage.getItem("menus");
  if (storeMenus) {
    // 获取当前的路由对象名称数组
    const currentRouteNames = router.getRoutes().map((v) => v.name);
    if (!currentRouteNames.includes("Manage")) {
      // 拼装动态路由
      const manageRoute = {
        path: "/",
        name: "Manage",
        component: () => import("@/views/Manage.vue"),
        redirect: "/home",
        meta: {
          title: "用户主页面",
          icon: "",
        },
        children: [
          {
            path: "home",
            name: "home",
            component: () => import("@/views/Home.vue"),
            meta: {
              title: "用户中心",
              icon: "lock",
            },
          },
          {
            path: "user",
            name: "user",
            component: () => import("@/views/User.vue"),
            meta: {
              title: "个人资料",
              icon: "lock",
            },
          },
          {
            path: "faq",
            name: "faq",
            component: () => import("@/views/Faq.vue"),
            meta: {
              title: "帮助中心",
              icon: "lock",
            },
          },
          {
            path: "shop/money",
            name: "money",
            component: () => import("@/views/shop/money"),
            meta: {
              title: "充值余额",
              icon: "lock",
            },
          },
          {
            path: "shop/quota",
            name: "quota",
            component: () => import("@/views/shop/quota"),
            meta: {
              title: "充值配额",
              icon: "lock",
            },
          },
          {
            path: "shop/ym",
            name: "ym",
            component: () => import("@/views/shop/ym"),
            meta: {
              title: "充值宥马",
              icon: "lock",
            },
          },
        ],
      };

      const menus = JSON.parse(storeMenus);

      menus.forEach((item) => {
        if (item.path) {
          // 当且仅当path不为空的时候才去设置路由

          let menuItem = {
            path: item.path.replace("/", ""),
            name: item.name,
            component: () => import("@/views/" + item.component),
            children: [],
          };

          item.children.forEach((item) => {
            let subItem = {
              path: item.path.replace("/", ""),
              name: item.name,
              component: () => import("@/views/" + item.component),
              meta: {
                title: item.title,
                icon: item.icon,
              },
            };

            manageRoute.children.push(subItem);
          });

          manageRoute.children.push(menuItem);
        }
      });

      // 动态添加到现在的路由对象中去
      router.addRoute(manageRoute);
    }
  }
};

// 重置我就再set一次路由
setRoutes();

// 提供一个重置路由的方法
export const resetRouter = () => {
  router.matcher = createRouter({
    history: createWebHistory(),
    routes,
  });
};

router.beforeEach((to, from, next) => {
  window.document.title = to.meta.title;

  // 未找到路由的情况
  if (!to.matched.length) {
    const storeMenus = localStorage.getItem("menus");
    if (storeMenus) {
      next("/404");
    } else {
      // 跳回登录页面
      next("/login");
    }
  }
  // 其他的情况都放行
  next();
});

export default router;
